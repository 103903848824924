
import { Options } from "vue-class-component";
import NodesMinxins from "../NodesMixins";

@Options({
    name: "flowable-nodes-approval-node",
    emits: ["selected", "delNode", "insertNode"],
})
export default class ApprovalNode extends NodesMinxins {
    private showError = false;
    private errorInfo = "";

    get headerBgc() {
        if (this.diagramMode === "viewer") {
            return this.config.props.headerBgc;
        } else {
            return "#ff943e";
        }
    }
    get content() {
        const config = this.config.props;
        switch (config.assignedType) {
            case "SELF":
                return "发起人自己";
            case "SELF_SELECT":
                return config.selfSelect.multiple ? "发起人自选多人" : "发起人自选一人";
            case "ASSIGN_USER":
                if (config.assignedUser.length > 0) {
                    let texts: any = [];
                    config.assignedUser.forEach((user: any) => texts.push(user.title));
                    return String(texts).replaceAll(",", "、");
                } else {
                    return "请指定审批人";
                }
            case "ASSIGN_ORG":
                if (config.assignedOrg.length > 0) {
                    let texts: any = [];
                    config.assignedOrg.forEach((org: any) => texts.push(org.title));
                    return String(texts).replaceAll(",", "、");
                } else {
                    return "请指定审批组织";
                }
            case "ASSIGN_DEPT":
                if (config.assignedDept.length > 0) {
                    let texts: any = [];
                    config.assignedDept.forEach((dept: any) => texts.push(dept.title));
                    return String(texts).replaceAll(",", "、");
                } else {
                    return "请指定审批部门";
                }
            case "LEADER":
                return config.leader.level > 1 ? "发起人的第 " + config.leader.level + " 级主管" : "发起人的直接主管";
            case "LEADER_TOP":
                return "多级主管依次审批";
            case "AREA_LEADER":
                return config.areaLeader.level > 1 ? "发起人的第 " + config.areaLeader.level + " 级区域负责人" : "发起人的上级区域负责人";
            case "AREA_PROJECT_LEADER":
                let text1 = this.getFormItemById(config.areaProjectLeader.formProject);
                if (text1 && text1.title) {
                    text1 = `，表单（${text1.title}）内的设备`;
                } else {
                    text1 = "，未指定表单设备😥";
                }
                let text31 = "";
                if (config.areaProjectLeader.level === 0) {
                    text31 = "设备所属组织上级条线所有区域负责人";
                } else if (config.areaProjectLeader.level === 1) {
                    text31 = "设备所属组织的上级区域负责人";
                } else if (config.areaProjectLeader.level > 1) {
                    text31 = "设备所属组织的第 " + config.areaProjectLeader.level + " 级区域负责人";
                } else {
                    text31 = "未配置层级";
                }
                return text31 + text1;
            case "AREA_PROJECT_HANDLE":
                let text2 = this.getFormItemById(config.areaProjectHandle.formProject);
                if (text2 && text2.title) {
                    text2 = `，表单（${text2.title}）内的设备`;
                } else {
                    text2 = "，未指定表单设备😥";
                }
                let text3 = "";
                if (config.areaProjectHandle.level === 0) {
                    text3 = "设备所属组织上级条线所有区域处理人";
                } else if (config.areaProjectHandle.level === 1) {
                    text3 = "设备所属组织的上级区域处理人";
                } else if (config.areaProjectHandle.level > 1) {
                    text3 = "设备所属组织的第 " + config.areaProjectHandle.level + " 级区域处理人";
                } else {
                    text3 = "未配置层级";
                }
                return text3 + text2;
            case "AREA_ORGCODE_LEADER":
                let text11 = this.getFormItemById(config.areaOrgCodeLeader.formOrgCode);
                if (text11 && text11.title) {
                    text11 = `，表单（${text11.title}）内的组织`;
                } else {
                    text11 = "，未指定表单组织😥";
                }
                let text14 = "";
                if (config.areaOrgCodeLeader.level === 0) {
                    text14 = "所选组织上级条线所有区域负责人";
                } else if (config.areaOrgCodeLeader.level === 1) {
                    text14 = "所选组织的上级区域负责人";
                } else if (config.areaOrgCodeLeader.level > 1) {
                    text14 = "所选组织的第 " + config.areaOrgCodeLeader.level + " 级区域负责人";
                } else {
                    text14 = "未配置层级";
                }
                return text14 + text11;
            case "AREA_ORGCODE_HANDLE":
                let text12 = this.getFormItemById(config.areaOrgCodeHandle.formOrgCode);
                if (text12 && text12.title) {
                    text12 = `，表单（${text12.title}）内的组织`;
                } else {
                    text12 = "，未指定表单组织😥";
                }
                let text13 = "";
                if (config.areaOrgCodeHandle.level === 0) {
                    text13 = "所选组织上级条线所有区域处理人";
                } else if (config.areaOrgCodeHandle.level === 1) {
                    text13 = "所选组织的上级区域处理人";
                } else if (config.areaOrgCodeHandle.level > 1) {
                    text13 = "所选组织的第 " + config.areaOrgCodeHandle.level + " 级区域处理人";
                } else {
                    text13 = "未配置层级";
                }
                return text13 + text12;
            case "FORM_USER":
                if (!config.formUser || config.formUser === "") {
                    return "表单内联系人（未选择）";
                } else {
                    let text = this.getFormItemById(config.formUser);
                    if (text && text.title) {
                        return `表单（${text.title}）内的人员`;
                    } else {
                        return "未指定表单人员😥";
                    }
                }
            default:
                return "未知设置项😥";
        }
    }
    public getFormItemById(id: any) {
        return this.setup.formItems.find((item: any) => item.id === id);
    }
    //校验数据配置的合法性
    public validate(err: any) {
        try {
            this.showError = !(this as any)[`validate_${this.config.props.assignedType}`](err);

            if (this.config.props.nobody.handler === "TO_USER" && this.config.props.nobody.assignedUser.length === 0) {
                this.errorInfo = "审批人为空时， 转交给指定人员：【请指定一个具体的人】";
                err.push("审批人为空时， 转交给指定人员：【请指定一个具体的人】");
                this.showError = true;
            }

            if (this.formItems.length !== this.config.props.formPerms.length) {
                this.errorInfo = "表单审批节点权限部分缺失：【请打开审批流程配置审批节点】";
                err.push("表单审批节点权限部分缺失：【请打开审批流程配置审批节点】");
                this.showError = true;
            }

            return this.showError;
        } catch (e) {
            return true;
        }
    }
    public validate_ASSIGN_USER(err: any) {
        if (this.config.props.assignedUser.length > 0) {
            return true;
        } else {
            this.errorInfo = "请指定审批人员";
            err.push(`${this.config.name} 未指定审批人员`);
            return false;
        }
    }
    public validate_SELF_SELECT(err: any) {
        return true;
    }
    public validate_SELF(err: any) {
        return true;
    }
    public validate_ASSIGN_ORG(err: any) {
        if (this.config.props.assignedOrg.length > 0) {
            return true;
        } else {
            this.errorInfo = "请指定审批组织";
            err.push(`${this.config.name} 未指定审批组织`);
            return false;
        }
    }
    public validate_LEADER(err: any) {
        return true;
    }
    public validate_LEADER_TOP(err: any) {
        return true;
    }
    public validate_ASSIGN_DEPT(err: any) {
        if (this.config.props.assignedDept.length > 0) {
            return true;
        } else {
            this.errorInfo = "请指定审批部门";
            err.push(`${this.config.name} 未指定审批部门`);
            return false;
        }
    }
    public validate_AREA_LEADER(err: any) {
        return true;
    }
    public validate_AREA_PROJECT_LEADER(err: any) {
        if (this.config.props.areaProjectLeader.formProject === "") {
            this.errorInfo = "请指定表单设备字段";
            err.push(`${this.config.name} 审批人为设备所属组织的区域负责人，但未指定设备字段`);
            return false;
        }
        return true;
    }
    public validate_AREA_ORGCODE_LEADER(err: any) {
        if (this.config.props.areaOrgCodeLeader.formOrgCode === "") {
            this.errorInfo = "请指定表单组织字段";
            err.push(`${this.config.name} 审批人为所选组织的区域负责人，但未指定组织字段`);
            return false;
        }
        return true;
    }
    public validate_FORM_USER(err: any) {
        if (this.config.props.formUser === "") {
            this.errorInfo = "请指定表单中的人员组件";
            err.push(`${this.config.name} 审批人为表单中人员，但未指定`);
            return false;
        }
        return true;
    }
}
